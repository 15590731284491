import React from "react";
import ClaimPage2 from "../client-ui/bus/ClaimPage2";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*{
  margin:0;
  padding:0;
}

html, body {
    width : 100vw;
    minWidth : 350px;
    background-color : white;
    -webkit-overflow-scrolling: touch;
}

body {
  position: relative;
  width: 100%;
  overflow: hidden;
  overflow-y: visible;

}

img{
  max-width:100%;
}


@media (max-width: 350px){
    html, body {
        width : 350px;
        overflow-x: scroll;

    }
    
}





`;


export default () => {
    return (
        
        <>
            <GlobalStyle/>
             
            {/* <div style={{ width : '100%', height : 50, textAlign : 'right', backgroundColor : 'red' }}>
                <div style={{ width : '100%'  }}>1</div>
            </div>
            <div style={{ marginLeft : 349, height : 50, width : 1, backgroundColor : 'black'}}/> */}
            <ClaimPage2/>
        </>
        
    )
}